
import { Box, Container, Grid, keyframes, Typography } from '@mui/material';
import React from 'react';
import bannerImg from './../../images/lead-generation/global-database.png';
import MainButton from '../MainButton/MainButton';
import Navbar from '../Navbar/Navbar';

const upDown = keyframes`

0%{
    transform: translateY(5px);
}
60%{
    transform: translateY(-5px);
}
100%{
    transform: translateY(5px);
}
`;

const Header = () => {

    return (
        <Box component={'div'} id='home' sx={{ background: "linear-gradient(230deg, #0196c4 20%, #082351 66%)" }}>
            <Navbar />
            <Container>
                <Grid container spacing={2} sx={{ mt: { xs: 5, md: 3 } }}>
                    <Grid item md={6} xs={12} sx={{ display: "flex", alignItems: "center" }}>
                        <Box component={'div'} sx={{ '& a': { textDecoration: "none" } }}>
                            <Typography sx={{ fontSize: 44, color: '#fff', fontWeight: 700 }} variant='h3'>The most accurate <br /> B2B Database
                            </Typography>
                            <Typography sx={{ fontSize: 17, color: "#fff", my: 3 }} >Access over 210 million business leads</Typography>

                            <a href="https://docs.google.com/forms/d/1NRtFWoBc4IBbcY7VTBG6BMIVonWvEHKh5YIg57jhC9M/edit" target="_blank" rel="noopener noreferrer">
                                <MainButton variant='contained' sx={{ textTransform: "capitalize" }}>Get the sample</MainButton>
                            </a>
                        </Box>
                    </Grid>
                    <Grid item md={6} xs={12} sx={{ display: "flex", alignItems: "center", textAlign: "center", mt: { md: 0, xs: 3 } }}>
                        <Box>
                            <Typography sx={{ fontSize: 32, color: '#fff', fontWeight: 700, }}>B2B Targeted Email List</Typography>
                            <Box component={'div'} sx={{ '& img': { width: '80%' }, animation: `${upDown} 3s linear infinite` }}>
                                <img src={bannerImg} alt="banner" />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
            <Box component={'div'} className="banner-shape"></Box>
        </Box>
    );
};

export default Header;